import { Atom, SHARED_VALIDATIONS } from ":mods";



export function createOTPInputs() {
  
  const OTP = Atom.Form.createInput(null, {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.otp.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    }
  });
  
  const Actions = Atom.Form.createFormActions({ OTP});
  return {
    Actions,
    OTP
  };
}
